import { useEffect, useState } from "react";
import "./payment.css";
import { QRCode } from "components/QRCode.tsx";
import { useParams } from "react-router-dom";
import {
  useChannel,
  useEventHandler,
} from "@ericlathrop/phoenix-js-react-hooks";
import clsx from "clsx";
import LoadingState from "components/charge/LoadingState";
import SuccessState from "components/charge/SuccessState";
import NotFound from "./NotFound";

const PAYMENT_METHODS = [
  // { name: "Metamask", img: "metamask.svg" },
  { name: "QRCode", img: false },
];

export default function Payment() {
  const { chargeId } = useParams();

  const [charge, setCharge] = useState(undefined);
  const [messages, setMessages] = useState([]);

  const [selectedCrypto, selectCrypto] = useState(undefined);
  const [selectedMethod, selectMethod] = useState(undefined);

  const channel = useChannel(
    `charge:${chargeId}`,
    undefined,
    (channel, messages) => {
      setCharge(messages.charge);
      console.log("charge", messages.charge);
    }
  );

  useEventHandler(channel, "state", (message) => {
    setMessages(messages.slice(0).concat([message]));
  });

  useEventHandler(channel, "charge:updated", (message) => {
    setCharge(message);
  });

  useEffect(() => {
    if (selectedMethod && selectedMethod.name === "Metamask") {
      window.location.href =
        `https://metamask.app.link/send/` + selectedCrypto.payment_uri;
    }
  }, [selectedCrypto, selectedMethod, charge]);

  if (charge === undefined) {
    return <NotFound />;
  }

  if (charge.status === "pending") {
    return (
      <div id="pattern-background" className="bg-fuchsia-100 min-h-screen">
        <div className="container mx-auto pt-20">
          <div className="w-[500px] shadow-md rounded-lg p-5 mx-auto bg-white">
            <LoadingState charge={charge} />
          </div>
        </div>
      </div>
    );
  }

  if (charge.status === "completed") {
    return (
      <div id="pattern-background" className="bg-fuchsia-100 min-h-screen">
        <div className="container mx-auto pt-20">
          <div className="w-[500px] shadow-md rounded-lg p-5 mx-auto bg-white">
            <SuccessState charge={charge} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="pattern-background" className="bg-fuchsia-100 min-h-screen">
      <div className="container mx-auto md:pt-20">
        <div className="w-full md:w-[500px] shadow-md md:rounded-lg p-5 mx-auto bg-white">
          <div className="text-lg font-bold text-black mb-2">
            Select a currency
          </div>

          <div className="flex gap-2">
            {charge.pricing.map((pricing, index) => (
              <button
                className={clsx(
                  "flex items-center p-2 rounded-lg border border-gray-100 justify-between transition-all transition-duration-100 hover:bg-blue-100 hover:border-blue-200",
                  pricing.crypto.id === selectedCrypto?.crypto?.id &&
                    "border-blue-500 bg-blue-100"
                )}
                key={pricing.crypto.id}
                onClick={() => {
                  selectCrypto(pricing);
                }}
              >
                <img
                  src={require(`assets/icons/color/${pricing.crypto.symbol.toLowerCase()}.svg`)}
                  alt={pricing.crypto}
                  className="img-fluid w-[18px] h-[18px] mr-1"
                />
                <div className="text-sm font-medium text-black">
                  {pricing.crypto.name}
                </div>
              </button>
            ))}
          </div>

          {selectedCrypto && (
            <>
              <div className="text-lg font-bold text-black mt-4 mb-2">
                Select a payment method
              </div>
              <div className="flex flex-col gap-2">
                {PAYMENT_METHODS.map((item) => (
                  <button
                    className={clsx(
                      "flex w-full items-center p-4 rounded-lg border border-gray-100 transition-all transition-duration-100 hover:bg-blue-100 hover:border-blue-200",
                      item.name === selectedMethod?.name &&
                        "border-blue-500 bg-blue-100"
                    )}
                    key={item.name}
                    onClick={() => {
                      selectMethod(item);
                    }}
                  >
                    {item.img && (
                      <img
                        src={require(`assets/icons/color/${item.img}`)}
                        alt={""}
                        className="img-fluid w-[24px] h-[24px] mr-1"
                      />
                    )}
                    <div className="text-md font-medium text-black">
                      {item.name}
                    </div>
                  </button>
                ))}
              </div>
            </>
          )}

          {selectedCrypto && (
            <div className="">
              <div className="text-lg font-bold text-black mt-4 mb-3">
                Pay now
              </div>

              <QRCode
                uri={selectedCrypto.payment_uri}
                logoBackground="black"
                logoPosition="center"
                logoUrl={require(`assets/icons/color/${selectedCrypto.crypto.symbol.toLowerCase()}.svg`)}
                logoSize={28}
              />

              <div className="flex items-center my-3">
                <div className="flex-1 h-[1px] mt-[1px] bg-gray-200"></div>
                <div className="leading-none px-2">or</div>
                <div className="flex-1 h-[1px] mt-[1px] bg-gray-200"></div>
              </div>

              <div>
                <label className="font-medium text-sm">Address (ERC-20)</label>
                <div className="rounded-lg overflow-scroll bg-gray-50 px-2 py-2 font-mono text-sm border border-gray-200">
                  {selectedCrypto.address}
                </div>
              </div>

              <div className="mt-2">
                <label className="font-medium text-sm">Amount</label>
                <div className="rounded-lg overflow-scroll bg-gray-50 px-2 py-2 font-mono text-sm border border-gray-200">
                  {selectedCrypto.price.slice(0, 8)}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="mt-2 text-center">
          <span className="text-xs font-medium text-gray-400">
            Powered by{" "}
            <a href="https://get-peach.com/" className="text-gray-500">
              Peach
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}
