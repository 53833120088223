export default function NotFound() {
  return (
    <div id="pattern-background" className="bg-fuchsia-100 min-h-screen">
      <div className="container mx-auto pt-20 text-center">
        <h2 className="font-semibold text-3xl">Not Found</h2>
        <p>
          Go to{" "}
          <a href="https://get-peach.com/" className="underline text-blue-400">
            homepage
          </a>
          .
        </p>
      </div>
    </div>
  );
}
