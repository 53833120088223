import { useEffect } from "react";

export default function SuccessState({ charge }) {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = charge.success_redirect_url;
    }, 5000);
  });

  return (
    <>
      <div className="font-semibold text-center text-lg">Order completed</div>
      <div className="my-4 text-green-600">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-10 h-10 mx-auto"
        >
          <path
            fillRule="evenodd"
            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="text-center">
        <p>
          You can return to the merchant here. If any issue, you can contact the
          merchant at {charge.channel.merchant.support_email}
        </p>
      </div>
    </>
  );
}
