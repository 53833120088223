import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { SocketProvider } from "@ericlathrop/phoenix-js-react-hooks";
import posthog from "posthog-js";

import Payment from "./pages/payment";
import NotFound from "pages/NotFound";

const router = createBrowserRouter([
  {
    path: "/payment/:chargeId",
    element: <Payment />,
  },
  {
    path: "*", // catch-all route
    element: <NotFound />,
  },
]);

const socketUrl =
  process.env.REACT_APP_PEACH_WEBSOCKET_URL || "ws://localhost:4050/socket";

if (process.env.NODE_ENV === "production") {
  posthog.init("phc_72nKbJHIzod8n3NG5JShNrhLQdKSr6MNnefSDzRFmJo", {
    api_host: "https://app.posthog.com",
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SocketProvider url={socketUrl} options={{}}>
      <RouterProvider router={router} />
    </SocketProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
